import React, { useState } from 'react';
import {
    Button
} from '@msdyn365-commerce-modules/utilities';
import { ICartState } from '@msdyn365-commerce/global-state';
import { IActionContext } from '@msdyn365-commerce/core-internal/dist/types/interfaces/action';
//import { Cart } from '@msdyn365-commerce/retail-proxy';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { getCustomerObjectsAsync,addCustomerObjectsAffiliationsAsync } from '../../../actions/CustObjDataActionExtension.g';
import { ICustomerObjectsModel } from '../../../actions/CustObjDataServiceEntities.g';
import MsDyn365 from '@msdyn365-commerce/core';
import Cookies from 'universal-cookie';

export interface ICustomerObjectsComponentProps {
    user: string;
    callerContexts: IActionContext;
    cart: ICartState|undefined;
}

export const CustomerObjectComponent: React.FC<ICustomerObjectsComponentProps> = ({ user,callerContexts, cart}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    let customerObject:ICustomerObjectsModel[]=[];
    const [customerObjects,setCustomerObjects] = useState(customerObject);  
    const cookies = new Cookies(); 
    var customerObjectCookie=cookies.get('customerobject')??'';
    const [custObjectId, setCustObjectId]=useState(customerObjectCookie);



    const _toggleModal = (): void => { setIsModalOpen(!isModalOpen) };
    const _focusModal = (): void => { if (MsDyn365.isBrowser && isModalOpen) { document.getElementById('CustomerObjectModal')?.focus(); } };
    
    const _applyCustomerObjectoncart = async (cartState: ICartState | undefined, custObjId: string) => {
        try{
        await addCustomerObjectsAffiliationsAsync({ callerContext: callerContexts }, custObjId, cartState!.cart.Id);
        const cookies = new Cookies(); 
        cookies.set('customerobject', custObjId, { path: '/' });             
        

        
        setCustObjectId(custObjId);
        setIsModalOpen(false);
    }catch(error)
    {
    }
    }

   const _getCustomerObject = async(cartState: ICartState | undefined,) => {
    if (!cartState || !cartState.cart) {
        return;
    }
    var custid = cartState.cart.CustomerId;
    console.log(custid);
    try {
        const customerObjResult = await getCustomerObjectsAsync({ callerContext: callerContexts }, custid!);
        if (customerObjResult !== undefined) {            
            setCustomerObjects(customerObjResult);
           // customerObjResult.forEach((custobj)=>{
             //   customerObjects.push(customerObjResult[0]);
            //});
            
           console.log({ customerObjects: customerObjResult, isModalOpen: true });
        } else {
            console.log({ customerObjects: [] });
        }
        // console.log("HSO Start " , customerObjResult);
    }
    catch (error) {
        console.error('Error', error);
    }
    };

   const _applyCustomerObject = () => {
   _getCustomerObject(cart).then(()=>{
    setIsModalOpen(true);    
   });   
   
    }; 
  
    return (
        <div className='msc-promo-code__group'>
            <input
                className='msc-promo-code__input-box'
                aria-label={'Cust Object'}
                placeholder={'Cust Object'}
                value={custObjectId}
                disabled
            />
            <Button
                title='Customer Object'
                className='msc-promo-code__apply-btn btn'
             onClick={_applyCustomerObject}
            // {...attributes}
            >
                {'Customer Object'}
            </Button>

            {/* {isModalOpen? 'opened': 'closed'} */}

            <Modal id={'CustomerObjectModal'} onLoad={_focusModal} toggle={_toggleModal} autoFocus={true} fade={false} isOpen={isModalOpen} horizontalPosition={'center'} verticalPosition={'center'} zIndex={10011} applicationNode={'rsg-root'} className='modal'>

                    <ModalHeader className='modal-header' toggle={_toggleModal} >
                        <h1>Customer Object </h1>
                    </ModalHeader>
                    <ModalBody>

                    <table className='table-latitude'>
                                    <thead>
                                      <th></th>  <th>{' Id'}</th><th>{'Name'}</th><th>{'Address'}</th><th>{'Zip'}</th>
                                    </thead>
                            {
                                customerObjects && customerObjects.map(customerObj => {                                   
                                    return (<tr key={customerObj.CustomerObjectId} onClick={() => _applyCustomerObjectoncart(cart , customerObj.CustomerObjectId!)}>
                                          <td> <input type="radio" id={customerObj.CustomerObjectId} name="CustomerObject" /></td>
                                          <td>{customerObj.CustomerObjectId}</td>
                                          <td>{customerObj.Name}</td>
                                          <td>{customerObj.Address}</td>
                                          <td> {customerObj.ZipCode}</td>
                                    </tr>)
                                })

                            }
                        </table>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>

                </Modal>
        </div>
    );
};

export default CustomerObjectComponent;

/*async function _applyCustomerObject():Promise<void> 
{

}
*/